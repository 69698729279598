<template>
  <div>
    <v-row justify="center" class="container5">
      <v-col cols="12" sm="6" lg="4">
        <div class="form-card mb100 mt100">
          <div class="form-header">
            <h2>Contactez-nous</h2>
          </div>
          <form action="" method="get" class="form1" @submit.prevent="SendMailContact">
            <v-row>
              <v-col cols="12">
                <label>Votre nom (obligatoire)</label>
                <input type="text" name="name" v-model="nameInput" required>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Votre e-mail (obligatoire)</label>
                <input type="text" name="e-mail" v-model="emailInput" required>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Société</label>
                <input type="text" name="societe" v-model="societeInput">
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Téléphone</label>
                <input type="tel" name="telephone" v-model="telephoneInput">
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Sujet</label>
                <input type="text" name="sujet" v-model="sujetInput" required>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label>Message</label>
                <textarea name="message" id="message" v-model="messageInput" rows="6" required></textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <button type="submit" class="submit-button">Envoyer</button>
              </v-col>
              </v-row>
          </form>
        </div>
      </v-col>
    </v-row>
     <v-dialog v-model="successDialog" max-width="400px">
    <v-card class="success-dialog-card">
      <v-card-title class="headline">Email bien envoyé</v-card-title>
      <v-card-text>Votre message a été envoyé avec succès.</v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="successDialog = false">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <foot/>
  </div>
</template>

<script>
import foot from '@/components/Footer.vue'
export default {
  components:{
      foot
    },
		name: 'Login',
		data(){
			return {
				nameInput : '',
        emailInput: '',
        societeInput: '',
        sujetInput: '',
        messageInput: '',
        successDialog: false,
        telephoneInput: '',
			}
		},
		
		methods: {
      sendhey(){
        console.log('hey')
      },
    async SendMailContact() {
      const url = this.$api.getRESTApiUri() + '/envoie-contact';
      const data = {   // Create a regular JavaScript object instead of FormData
        sujet: this.sujetInput,
        societe: this.societeInput,
        nom: this.nameInput,
        email: this.emailInput,
        message: this.messageInput,
        tel: this.telephoneInput,
      };
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',  // Set the content type to JSON
          },
          body: JSON.stringify(data), // Convert the data object to a JSON string
        });

        if (response.ok) {
          const result = await response.json(); // Parse the response as JSON
          console.log(result);
          this.successDialog = true;
          this.nameInput = '',
          this.emailInput = '',
          this.societeInput = '',
          this.sujetInput = '',
          this.messageInput = '',
          this.telephoneInput = '',
          console.log(this.successDialog) // Afficher la fenêtre de notification
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }


		}
	}

</script>

<style scoped>
.container5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.form-header h2 {
  margin-left: 10px;
  color: var(--vertfonce)
}

.form1 label {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: var(--vertfonce);
}

.form1 input,
.form1 textarea {
  width: 100%;
  border: 1px solid var(--grisclair);
	border-radius: 5px;
  background-color: white;
  height: 35px;
  padding: 10px;
  margin-bottom: 15px;
}

.form1 textarea {
  resize: vertical;
}

.submit-button {
  background-color: var(--vertfonce);
  color: white !important;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--vertfonce);
}

.contact-info {
  margin-top: 30px;
  text-align: center;
}

.contact-info h3 {
  margin-bottom: 10px;
}

.contact-info p {
  margin: 5px 0;
}

#message{
    height: 250px;
}

.success-dialog-card { /* Set the background color for the dialog */
  border-radius: 10px;
  padding: 20px;
  text-align: center; /* Center the content within the dialog */
}

.success-dialog-card .headline {
  color: var(--vertfonce); /* Set the color for the headline text */
  font-size: 24px; /* Customize the font size for the headline */
  margin-bottom: 16px; /* Add some space below the headline */
}

.success-dialog-card .v-card-text {
  color: var(--grisclair); /* Set the color for the text content */
  font-size: 18px; /* Customize the font size for the text content */
  margin-bottom: 24px; /* Add some space below the text content */
}

.success-dialog-card .v-card-actions {
  display: flex;
  justify-content: center; /* Center the button within the dialog */
}

.success-dialog-card .v-card-actions .v-btn {
  color: var(--vertclair); /* Set the text color for the button */
  background-color: var(--vertfonce); /* Set the background color for the button */
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.success-dialog-card .v-card-actions .v-btn:hover {
  background-color: var(--vertfonce); /* Set the background color for the button on hover */
}

@media (max-width: 767px) {
.container5{
  margin-top: 50px;
  margin-bottom: 50px;
}

.form-card{
  margin-left: 5%;
  width: 90%;
}

}
</style>